.phone-number-input-container {
  padding-bottom: 0px !important;
}

.phone-number-input-container ul {
  border: solid 1px #b2b2b2 !important;
  max-width: 320px;
  min-width: 320px;
}

.phone-number-input-container ul li {
  font-size: 14px;
}

.phone-number-input-container ul div:first-child input {
  width: calc(100% - 20px);
  height: unset;
  font-size: 12px;
  border: solid 1px #b2b2b2 !important;
  color: #b2b2b2 !important;
  text-align: left !important;
}

.phone-number-input-container ul div:first-child input::placeholder {
  text-align: left !important;
}

.phone-number-input-select-bordered {
  height: 100%;
  border: solid 1px #b2b2b2 !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  padding: 5px !important;
  width: 110px !important;
}

.phone-number-input-select {
  height: 100%;
  border: solid 1px #000 !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-top: 0 !important;
}

.phone-number-input-select-mat {
  height: 100%;
  border: none !important;
}

.input-field-style {
  text-align: left !important;
}

.input-field-style::placeholder {
  text-align: left !important;
}
