@font-face {
  font-family: 'Shoptype';
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/roboto-v20-latin-300.eot);
  src: url(./assets/fonts/roboto-v20-latin-300.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/roboto-v20-latin-300.woff2) format('woff2'),
    url(./assets/fonts/roboto-v20-latin-300.woff) format('woff');
}

@font-face {
  font-family: 'Shoptype';
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/roboto-v20-latin-regular.eot);
  src: url(./assets/fonts/roboto-v20-latin-regular.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/roboto-v20-latin-regular.woff2) format('woff2'),
    url(./assets/fonts/roboto-v20-latin-regular.woff) format('woff');
}

@font-face {
  font-family: 'Shoptype';
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/roboto-v20-latin-500.eot);
  src: url(./assets/fonts/roboto-v20-latin-500.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/roboto-v20-latin-500.woff2) format('woff2'),
    url(./assets/fonts/roboto-v20-latin-500.woff) format('woff');
}

@font-face {
  font-family: 'Shoptype';
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/roboto-v20-latin-700.eot);
  src: url(./assets/fonts/roboto-v20-latin-700.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/roboto-v20-latin-700.woff2) format('woff2'),
    url(./assets/fonts/roboto-v20-latin-700.woff) format('woff');
}

@font-face {
  font-family: 'Shoptype';
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/roboto-v20-latin-700.eot);
  src: url(./assets/fonts/roboto-v20-latin-700.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/roboto-v20-latin-700.woff2) format('woff2'),
    url(./assets/fonts/roboto-v20-latin-700.woff) format('woff');
}

@font-face {
  font-family: 'Testimony';
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/yesteryear-v8-latin-regular.eot);
  src: url(./assets/fonts/yesteryear-v8-latin-regular.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/yesteryear-v8-latin-regular.woff2) format('woff2'),
    url(./assets/fonts/yesteryear-v8-latin-regular.woff) format('woff');
}

#root {
  height: 100%;
  overflow: hidden;
  background-color: #fbfaf8;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100vh;
  overflow: auto;
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Shoptype', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  vertical-align: middle;
}

button {
  -moz-appearance: none !important;
  font-family: 'Shoptype', sans-serif;
}

input {
  font-family: 'Shoptype', sans-serif;
}

a {
  text-decoration: none;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.success {
  color: #47b881;
}

.danger {
  color: #ec4c47;
}

.info {
  color: #1070ca;
}

.warning {
  color: #d9822b;
}
