.container {
  width: 100%;
  max-width: 480px;
  margin: auto;
}

.accordion {
  width: 100%;
  margin: 0;
  color: #fff;
}

.accordionHeading {
  height: 65px;
  display: flex;
  border: 0.25px solid #dedede;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.typoClass {
  padding-left: 15px;
  color: #484848;
  text-align: left;
}

.accordionHeading .container {
  display: flex;
  justify-content: space-between;
}

.rectangle {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  background-color: #f8f8f8;
}

.rectangle8 {
  height: 50px;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  background-color: #f8f8f8;
  border: solid yellow 2px;
}
.rectangle1 {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-top: 5px;
  height: 40px;
  width: 100%;
  background-color: #f8f8f8;
  border: solid #f8f8f8 1px;
}

.rectangle2 {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-top: 10px;
  height: 100px;
  width: 85%;
  background-color: #ffffff;
  border-right: solid #d3d3d3 1px;
}

.rectangle3 {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100px;
  width: 15%;
  background-color: #ffffff;
  border: solid #f8f8f8 1px;
}

.rectangle4 {
  text-align: center;
  height: 40px;
  width: 85%;
  background-color: #f8f8f8;
  border-right: solid #d3d3d3 1px;
}

.topLevelContainer {
  width: 100%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
}

.rectangle5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  width: 15%;
  background-color: #f8f8f8;
  border: solid #f8f8f8 1px;
}

.newCard {
  transition: all 400ms ease-in-out !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.rectangle6 {
  height: 40px;
  width: 85%;
}

.rectangle51 {
  text-align: center;
  height: 40px;
  margin-bottom: 10px;
  width: 15%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
}

.rectangle52 {
  text-align: center;
  height: 50px;
  width: 15%;
  background-color: #ffffff;
}

.accordionHeading span {
  cursor: pointer;
}

.accordionContent {
  width: 100%;
  height: auto;
  transition: all 400ms ease-in-out !important;
}

.showaccordionContent {
  height: 0;
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.accordionContent p {
  font-size: 8px;
  line-height: 1.6;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 320px;
}

.flexColumn1 {
  display: flex;
  flex-direction: column;
}

.columnSpace {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.rowPadding {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.rowTopPadding {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.center {
  text-align: center;
}

.expandless {
  height: 60px;
  width: 25px;
  color: #808080;
}

.typoOne {
  padding-top: 17px;
  padding-bottom: 10px;
  font-size: 18px;
}

.fixedFont {
  font-size: 16px;
  color: #484848;
}

.typoTwo {
  padding-left: 5px;
  color: #484848;
  font-size: 16px;
}

.typoThree {
  text-align: 'center';
  color: #484848;
  font-size: 16px;
}

.typofour {
  font-size: 16px;
  color: #228b22;
  padding-right: 10px;
}

.typoFive {
  font-size: 16px;
  padding-right: 10px;
}

.typo6 {
  font-size: 16px;
}

.typo7 {
  padding-left: 15px;
  font-size: 16px;
  padding-bottom: 10px;
  color: #484848;
}

.typo8 {
  padding-left: 15px;
  color: #484848;
  font-size: 16px;
}

.typo9 {
  padding-top: 20px;
  color: #484848;
  font-size: 16px;
}

.typo10 {
  padding-top: 7px;
  cursor: pointer;
  font-size: 16px;
}

.typo11 {
  padding-top: 10px;
  cursor: pointer;
  font-size: 16px;
}

.typoPointer {
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}

.textStyle {
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
}

.topPadding {
  padding-top: 20px;
}

.refundText {
  height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wooCommReason {
  border: 1px solid grey;
  margin-left: 110px;
  width: 60%;
  margin-top: 40px;
  border-radius: 5px;
}

.shopifyReason {
  border: 1px solid grey;
  margin-left: 14px;
  margin-top: 40px;
  border-radius: 5px;
}

.wooComContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 25px;
}

.textArea {
  height: 150px;
  margin-top: 30px;
  margin-left: 16px;
}

.gap {
  height: 50px;
  margin-top: 30px;
  margin-left: 16px;
}

@media (max-width: 1311px) {
  .rectangle2 {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 10px;
    height: 100px;
    width: 80%;
    background-color: #ffffff;
    border-right: solid #d3d3d3 1px;
  }

  .rectangle3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100px;
    width: 20%;
    background-color: #ffffff;
    border: solid #f8f8f8 1px;
  }

  .rectangle4 {
    text-align: center;
    height: 40px;
    width: 80%;
    background-color: #f8f8f8;
    border-right: solid #d3d3d3 1px;
  }

  .rectangle5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40px;
    width: 20%;
    background-color: #f8f8f8;
    border: solid #f8f8f8 1px;
  }

  .rectangle6 {
    height: 40px;
    width: 80%;
  }

  .rectangle51 {
    text-align: center;
    height: 40px;
    width: 20%;
    background-color: #f8f8f8;
  }
}
